import React from 'react';
import PDFMagazineViewer from './components/PDFMagazineViewer';

function App() {
  return (
    <div className="App">
      <PDFMagazineViewer />
    </div>
  );
}

export default App;