import React, { useState, useRef, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './PDFMagazineViewer.css';

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref} data-density="soft">
      <img src={props.pageUrl} alt={`Page ${props.number}`} />
      <div className="page-number">{props.number}</div>
    </div>
  );
});

const PDFMagazineViewer = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [renderedPages, setRenderedPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1.414); // Default to A4
  const [currentPage, setCurrentPage] = useState(0);
  const book = useRef();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (window.pdfjsLib) {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.min.js`;
    } else {
      setError('PDF.js is not loaded. Please check your internet connection and reload the page.');
    }
  }, []);

  const loadPDF = async (file) => {
    if (!window.pdfjsLib) {
      setError('PDF.js is not loaded. Please try again later.');
      return;
    }

    setIsLoading(true);
    const fileReader = new FileReader();

    fileReader.onload = async function() {
      try {
        const typedarray = new Uint8Array(this.result);
        const loadingTask = window.pdfjsLib.getDocument(typedarray);
        const pdf = await loadingTask.promise;
        setNumPages(pdf.numPages);

        // Detect aspect ratio from the first page
        const firstPage = await pdf.getPage(1);
        const viewport = firstPage.getViewport({ scale: 1 });
        setAspectRatio(viewport.width / viewport.height);

        const pages = await Promise.all(
          Array.from({ length: pdf.numPages }, (_, i) => 
            renderPage(pdf, i + 1, viewport.width, viewport.height)
          )
        );
        setRenderedPages(pages);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load PDF. Please try a different file.');
        console.error('Error loading PDF:', err);
        setIsLoading(false);
      }
    };

    fileReader.readAsArrayBuffer(file);
  };

  const renderPage = async (pdf, pageNum, width, height) => {
    const page = await pdf.getPage(pageNum);
    const scale = 2; // Increased for better quality
    const viewport = page.getViewport({ scale });
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };
    await page.render(renderContext).promise;
    return canvas.toDataURL();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
      loadPDF(file);
    } else {
      setError('Please upload a PDF file.');
    }
  };

  const onFlip = (e) => {
    setCurrentPage(e.data);
  };

  const handlePrevPage = () => {
    book.current.pageFlip().flipPrev();
  };

  const handleNextPage = () => {
    book.current.pageFlip().flipNext();
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const bookWidth = 800; // Base width
  const bookHeight = bookWidth / aspectRatio;

  return (
    <div className="pdf-viewer">
      <div className="controls">
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <button onClick={() => fileInputRef.current.click()} className="upload-btn">
          Upload PDF
        </button>
      </div>
      {pdfFile && <p className="file-name">{pdfFile.name}</p>}

      {isLoading ? (
        <div className="loading-message">Loading PDF, please wait...</div>
      ) : renderedPages.length > 0 ? (
        <div className="book-container">
          <button className="nav-btn prev-btn" onClick={handlePrevPage} disabled={currentPage === 0}>
            <ChevronLeft size={24} />
          </button>
          <HTMLFlipBook
            width={bookWidth}
            height={bookHeight}
            size="stretch"
            minWidth={bookWidth / 2}
            maxWidth={bookWidth * 1.5}
            minHeight={bookHeight / 2}
            maxHeight={bookHeight * 1.5}
            maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
            onFlip={onFlip}
            className="demo-book"
            ref={book}
            flippingTime={1000}
            usePortrait={false}
            startZIndex={0}
            autoSize={true}
            clickEventForward={true}
            useMouseEvents={true}
            showPageCorners={true}
          >
            {renderedPages.map((pageUrl, index) => (
              <Page key={index} pageUrl={pageUrl} number={index + 1} />
            ))}
          </HTMLFlipBook>
          <button className="nav-btn next-btn" onClick={handleNextPage} disabled={currentPage >= numPages - 1}>
            <ChevronRight size={24} />
          </button>
        </div>
      ) : (
        <div className="upload-message">
          Upload a PDF to view it as a magazine.
        </div>
      )}
      {renderedPages.length > 0 && (
        <div className="page-info">
          Page {currentPage + 1} of {numPages}
        </div>
      )}
    </div>
  );
};

export default PDFMagazineViewer;